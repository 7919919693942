
import {computed, defineComponent, ref} from "vue";
import store from "@/store";
import countries from "@/core/data/countries";
import GuestInputGroup from "@/components/new-reservation/guest-info/GuestInputGroup.vue";
import AppCore from "@/core/appcore/AppCore";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import {Actions} from "@/store/enums/StoreEnums";

export default defineComponent({

    components: {
        GuestInputGroup,
    },

    setup(props, {emit}) {

        const ccvSearchCode = ref("");
        const theFlightReservationList = ref({});

        function setSearchResult(prmSearchCode, prmFlightReservationList) {
            ccvSearchCode.value = prmSearchCode;
            theFlightReservationList.value = prmFlightReservationList;
            getPax_By_SearchParams();
        }

        function processStep() {
            console.log("Stepper4.vue processStep");
        }

        let thePax = ref({});

        function getChildPaxBirthdate(prmPaxType, prmIndex) {
            //return (thePax.value as any).value
            if (prmPaxType==="ADT") {
                return "2005-01-01";
            } else {
                if (thePax.value!==null && (thePax.value as any).CHDBirthDates!==undefined) {
                    return (thePax.value as any).CHDBirthDates[prmIndex];
                }
            }

            return "";
        }

        function getPax_By_SearchParams() {
            //console.log("setPax START");
            if (AppCore.prsCore_Flight.theSearchParams.Pax!==undefined) {
                //console.log("setPax v1");
                //console.log("setPax v1a "+JSON.stringify(AppCore.prsCore_Flight.theSearchParams.Pax));
                if (AppCore.prsCore_Flight.theSearchParams.Pax.ADT!==undefined) {
                    thePax.value = AppCore.prsCore_Flight.theSearchParams.Pax;
                }
            }
        }

        function getPaxCode(prmIndex) {
            return "PAXCODE_"+prmIndex;
        }

        function getAllPaxes() {
            let rv = {
                ADTList : [],
                CHDList : [],
            };

            //ADT
            for (let i=0; i<Number((thePax.value as any).ADT); i++) {
                let eachPax = {
                    Type: "ADT",
                    Name: getElementValue("txt", "Name", "ADT", (i+1) ),
                    Surname: getElementValue("txt", "Surname", "ADT", (i+1) ),
                    Gender: getElementValue("sel", "Gender", "ADT", (i+1) ),
                    TcNo: getElementValue("txt", "TcNo", "ADT", (i+1) ),
                    Country: getCountryName(getElementValue("sel", "Country", "ADT", (i+1) )),
                    BirthDate: getElementValue("txt", "BirthDate", "ADT", (i+1) ),
                }
                rv.ADTList.push(eachPax);
            }

            //CHD
            for (let i=0; i<Number((thePax.value as any).CHD); i++) {
                let eachPax = {
                    Type: "CHD",
                    Name: getElementValue("txt", "Name", "CHD", (i+1) ),
                    Surname: getElementValue("txt", "Surname", "CHD", (i+1) ),
                    Gender: getElementValue("sel", "Gender", "CHD", (i+1) ),
                    TcNo: getElementValue("txt", "TcNo", "CHD", (i+1) ),
                    Country: getCountryName(getElementValue("sel", "Country", "CHD", (i+1) )),
                    BirthDate: getElementValue("txt", "BirthDate", "CHD", (i+1) ),
                }
                rv.CHDList.push(eachPax);
            }

            return rv;
        }

        function getCountryName(prmCountryCode) {
            for (let i=0; i<countries.TYPES.length; i++) {
                if (prmCountryCode === countries.TYPES[i].Value) {
                    return countries.TYPES[i].Title;
                }
            }
            return "";
        }

        function getElementValue(prmElType, prmIdCode, prmPaxType, prmIndex) {
            //txt_Step4_Name_ADT_1
            let elId =  prmElType + "_"  + "Step4" +"_" + prmIdCode + "_" + prmPaxType + "_" + prmIndex;
            //console.log("getElementValue elId: " + elId);
            let element = document.getElementById(elId) as any;
            if (element!==null) {
                const vvVal = element.value;
                //console.log("getElementValue vvVal: " + vvVal);
                return vvVal;
            }
            return "";
        }

        function checkPaxValues(prmPaxType, prmIndex) {
            let eachPax = {
                Name: getElementValue("txt", "Name", prmPaxType, (prmIndex+1) ),
                Surname: getElementValue("txt", "Surname", prmPaxType, (prmIndex+1) ),
                Gender: getElementValue("sel", "Gender", prmPaxType, (prmIndex+1) ),
                TcNo: getElementValue("txt", "TcNo", prmPaxType, (prmIndex+1) ),
                Country: getElementValue("sel", "Country", prmPaxType, (prmIndex+1) ),
            };

            if (eachPax.Name==="") {
                libTools.method.swalError("Tamam", Number(prmIndex+1)+". "+ libTools.method.getPaxTypeName(prmPaxType)+" için İsim girmelisiniz")
                return null;
            }

            if (eachPax.Surname==="") {
                libTools.method.swalError("Tamam", Number(prmIndex+1)+". "+ libTools.method.getPaxTypeName(prmPaxType)+" için Soyisim girmelisiniz")
                //libTools.method.swalError("Tamam", prmPaxType+ " "+prmIndex+" Surname Girmemişsiniz")
                return null;
            }

            if (eachPax.Gender==="" || eachPax.Gender==="Cinsiyet") {
                libTools.method.swalError("Tamam", Number(prmIndex+1)+". "+ libTools.method.getPaxTypeName(prmPaxType)+" için Cinsiyet seçmelisiniz")
                return null;
            }

            /*
            if (eachPax.TcNo==="") {
                libTools.method.swalError("Tamam", Number(prmIndex+1)+". "+ libTools.method.getPaxTypeName(prmPaxType)+" için T.C. No girmelisiniz")
                return null;
            }
            */

            if (eachPax.Country==="" || eachPax.Country==="Uyruk") {
                libTools.method.swalError("Tamam", Number(prmIndex+1)+". "+ libTools.method.getPaxTypeName(prmPaxType)+" için Uyruk seçmelisiniz")
                return null;
            }

            return eachPax;
        }

        function checkAllPaxValues() {
            let rv = {
                ADTList : [],
                CHDList : [],
            };

            for (let i=0; i<Number((thePax.value as any).ADT); i++) {
                const eachPax =  checkPaxValues("ADT", i);
                if (eachPax===null) { return false; }
                rv.ADTList.push(eachPax);
            }

            for (let i=0; i<Number((thePax.value as any).CHD); i++) {
                const eachPax =  checkPaxValues("CHD", i);
                if (eachPax===null) { return false; }
                rv.CHDList.push(eachPax);
            }

            return true;
        }

        function checkForNext() {
            if (!checkAllPaxValues()) {
                return false;
            }
            return true;
        }

        function click_Prev() {
            if (AppCore.prsCore_Flight.theSearchParams.TransferIncluded === 0) {
                emit("onGotoStepperStep", "prew", 2);
            } else {
                emit("onGotoStepperStep", "prew", 3);
            }
        }

        function click_Next() {
            if (checkForNext()) {
                const theAllPaxes = getAllPaxes();
                console.log("theAllPaxes: "+JSON.stringify(theAllPaxes));
                //{"ADTList":[{"Name":"Haluk","Surname":"Tatar","Gender":"1","TcNo":"234424","Country":"4"}],"CHDList":[]}
                sendSelectedItems(theAllPaxes);
                emit("onGotoStepperStep", "next", 5);
            }
        }

        function sendSelectedItems(prmAllPaxes) {

            let thePaxInfo = [];

            for (let i=0; i<prmAllPaxes.ADTList.length; i++) {
                let eachPax = prmAllPaxes.ADTList[i];
                thePaxInfo.push(eachPax);
            }

            for (let i=0; i<prmAllPaxes.CHDList.length; i++) {
                let eachPax = prmAllPaxes.CHDList[i];
                thePaxInfo.push(eachPax);
            }

            let vvPayload = {
                "SearchCode" : AppCore.prsCore_Flight.ccvSearchCode,
                "Type": "PAXINFO",
                "PaxInfo": thePaxInfo,
            };

            store.dispatch(Actions.FLIGHT_RESERVATION_ADD, vvPayload);
        }

        return {
            ccvSearchCode, theFlightReservationList,
            setSearchResult,
            processStep,
            thePax, getPaxCode,
            click_Prev, click_Next,
            getChildPaxBirthdate,
        };
    },

});
