import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = {
  class: "d-flex flex-row",
  id: "divRow_MakeReservation_Master_SearchResultMain",
  style: {"visibility":"hidden"}
}
const _hoisted_4 = { class: "flex-column card d-flex flex-lg-row w-100 me-0 justify-content-center" }
const _hoisted_5 = { class: "flex-column flex-lg-row-auto ms-auto order-1 order-lg-2 d-none d-xl-inline-block sticky-top ps-1" }
const _hoisted_6 = { style: {"display":"none"} }
const _hoisted_7 = { style: {"display":"none"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchCard = _resolveComponent("SearchCard")!
  const _component_SearchResultCard = _resolveComponent("SearchResultCard")!
  const _component_SummaryCard = _resolveComponent("SummaryCard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_SearchCard, {
          ref: "refComp_SearchCard",
          onOnSearchStarted: _ctx.searchStarted,
          onOnSearchTypeChanged: _ctx.searchTypeChanged
        }, null, 8, ["onOnSearchStarted", "onOnSearchTypeChanged"])
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_SearchResultCard, { ref: "refComp_SearchResultCard" }, null, 512)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_SummaryCard, { ref: "refComp_SummaryCard" }, null, 512)
      ]),
      _createElementVNode("p", _hoisted_6, "dataFlightReservationSearchParams: " + _toDisplayString(_ctx.dataFlightReservationSearchParams), 1),
      _createElementVNode("p", _hoisted_7, "dataFlightReservationList: " + _toDisplayString(_ctx.dataFlightReservationList), 1)
    ])
  ], 64))
}