
import {defineComponent} from "vue";

export default defineComponent({
    name: "Other Info",
    components: {
    },
    props: {

    },
    setup(props) {
        return {

        };
    },
});
