
import {computed, defineComponent, onMounted, ref} from "vue";
import AppCore from "@/core/appcore/AppCore";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import SummaryFlight from "@/components/new-reservation/summary/SummaryFlight.vue";
import {libEnums} from "@/core/appcore/libs/Lib_Enums";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";

export default defineComponent({

    components: {
        SummaryFlight,
    },
    setup() {

        let ccvSearchCode = ref("");
        let ccvSearchType = ref("");
        let theFlightReservationList = ref({});
        let theStepsBasket = ref ({});
        let ccvUpdateIndex = 0;

        onMounted(()=> {
            AppCore.prsCore_Flight.funcSummary_PrsStepsBasket = prsStepsBasket;
        });

        function setSearchResult(prmSearchCode, prmSearchType, prmFlightReservationList) {
            ccvSearchCode.value = prmSearchCode;
            ccvSearchType.value = prmSearchType;
            theFlightReservationList.value = prmFlightReservationList;

            (document.getElementById("divMakeReservation_SummaryCard_Sect_FlightInfo") as HTMLElement).style.display ="";
            if (prmSearchType===libEnums.SearchType.Hotel.toUpperCase()) {
                (document.getElementById("divMakeReservation_SummaryCard_Sect_FlightInfo") as HTMLElement).style.display ="none";
            }
        }

        function prsStepsBasket() {
            ccvUpdateIndex++;
            //console.log("prsStepsBasket START "+JSON.stringify(AppCore.prsCore_Flight.theStepsBasket));
            (theStepsBasket.value as any) = calcTotals(AppCore.prsCore_Flight.theStepsBasket);
            (theStepsBasket.value as any).UpdateIndex = ccvUpdateIndex;
        }

        function calcTotals(prmStepsBasket) {
            prmStepsBasket.RezTotal = 0;
            prmStepsBasket.UcakTotal = 0;
            prmStepsBasket.TransferTotal = 0;
            prmStepsBasket.CommisionTotal = 0;
            if (prmStepsBasket!==undefined) {
                if (prmStepsBasket.Step1!==undefined) {
                    if (prmStepsBasket.Step1.FlightDep!==undefined && prmStepsBasket.Step1.FlightDep.TotalPrice!==undefined) {
                        prmStepsBasket.UcakTotal += getTotalPrice(prmStepsBasket.Step1.FlightDep);  //prmStepsBasket.Step1.FlightDep.TotalPrice;
                    }
                    if (prmStepsBasket.Step1.FlightRet!==undefined && prmStepsBasket.Step1.FlightRet.TotalPrice) {
                        prmStepsBasket.UcakTotal += getTotalPrice(prmStepsBasket.Step1.FlightRet);  //prmStepsBasket.Step1.FlightRet.TotalPrice;
                    }
                }
                if (prmStepsBasket.Step2!==undefined) {
                    //console.log("calcTotals rez prmStepsBasket.Step2 START");
                    if (prmStepsBasket.Step2.RoomPrice!==undefined && prmStepsBasket.Step2.RoomPrice.Price.Totals.AccountPrice!==undefined) {
                        //console.log("calcTotals RoomPrice: "+JSON.stringify(prmStepsBasket.Step2.RoomPrice));
                        //console.log("calcTotals rez prmStepsBasket.Step2.RoomPrice.PosterPrice: "+prmStepsBasket.Step2.RoomPrice.PosterPrice)
                        //console.log("calcTotals rez Post Number: "+Number( (prmStepsBasket.Step2.RoomPrice.PosterPrice+"").replace(",", "")));
                        //console.log("calcTotals rez Acco Number: "+Number( (prmStepsBasket.Step2.RoomPrice.AccountPrice+"").replace(",", "")));

                        prmStepsBasket.RezTotal += Number((prmStepsBasket.Step2.RoomPrice.Price.Totals.AccountPrice+"").replace(",", ""));
                        if (prmStepsBasket.Step2.RoomPrice.Price.Totals.AccountPrice!==undefined) {
                            //prmStepsBasket.CommisionTotal += Number((prmStepsBasket.Step2.RoomPrice.Price.Totals.PosterPrice+"").replace(",", "")) - Number((prmStepsBasket.Step2.RoomPrice.Price.Totals.AccountPrice+"").replace(",", ""));
                            prmStepsBasket.CommisionTotal += Number((prmStepsBasket.Step2.RoomPrice.Price.Totals.CommissionAmount+"").replace(",", "")) ;
                        }
                        ccvHotelRate.value = prmStepsBasket.Step2.RoomPrice.Price.Totals.CommissionRate;
                    }
                }

                if (prmStepsBasket.Step3!==undefined) {
                    if (prmStepsBasket.Step3.TransferArrival!==undefined) {
                        prmStepsBasket.TransferTotal += Number(prmStepsBasket.Step3.TransferArrival.PosterPrice);
                    }
                    if (prmStepsBasket.Step3.TransferReturn!==undefined) {
                        prmStepsBasket.TransferTotal += Number(prmStepsBasket.Step3.TransferReturn.PosterPrice);
                    }
                }
            }
            //console.log("calcTotals END RezTotal: "+prmStepsBasket.RezTotal+" UcakTotal: "+prmStepsBasket.UcakTotal+" TransferTotal: "+prmStepsBasket.TransferTotal);
            prmStepsBasket.AllTotal = prmStepsBasket.TransferTotal + prmStepsBasket.UcakTotal + prmStepsBasket.RezTotal + prmStepsBasket.CommisionTotal;
            return prmStepsBasket;
        }

        function getTotalPrice(prmFlight) {
            let rv = 0;
            for (let i=0; i<prmFlight.thePrice.PaxPrices.length; i++) {
                const eachItem = prmFlight.thePrice.PaxPrices[i];
                rv+=eachItem.TotalPrice * libTools.method.getPaxCount_By_Result(theFlightReservationList.value, eachItem.PaxType);
            }
            return rv;
        }

        let thePax = ref({});

        function setPax(prmPax){
            thePax.value = prmPax;
        }

        let ccvHotelRate = ref(0);

        function click_ApplyHotelRate() {
            store.dispatch(Actions.FLIGHT_RESERVATION_SUMMARY_HOTEL_RATE, {SearchCode: ccvSearchCode.value, HotelRate: ccvHotelRate.value});
        }

        const dataHotelRateResult = computed(() => {
            const dataComputed = store.getters.getHotelRateResult;
            setHotelDataResult(dataComputed.data);
            return dataComputed;
        });

        function setHotelDataResult(prmData) {
            if (prmData!==undefined) {
                AppCore.prsCore_Flight.theStepsBasket.Step2.RoomPrice.Price.Totals.PosterPrice = prmData.Price.Totals.PosterPrice;
                AppCore.prsCore_Flight.theStepsBasket.Step2.RoomPrice.Price.Totals.PosterRate = prmData.Price.Totals.PosterRate;
                AppCore.prsCore_Flight.theStepsBasket.Step2.RoomPrice.Price.Totals.CommissionRate = prmData.Price.Totals.CommissionRate;
                AppCore.prsCore_Flight.theStepsBasket.Step2.RoomPrice.Price.Totals.CommissionAmount = prmData.Price.Totals.CommissionAmount;
                prsStepsBasket();
            }
        }

        return {
            AppCore, libTools,
            setSearchResult, ccvSearchCode, ccvSearchType,
            theStepsBasket,
            thePax,
            ccvHotelRate, click_ApplyHotelRate, dataHotelRateResult,
        };
    }

});
