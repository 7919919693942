

import {computed, defineComponent, ref} from "vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import AppCore from "@/core/appcore/AppCore";

import HotelContainer from "@/components/new-reservation/hotel-list/HotelContainer.vue";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import {libEnums} from "@/core/appcore/libs/Lib_Enums";

export default defineComponent({
    components: {
        HotelContainer,
    },
    setup(props, {emit}) {

        const ccvSearchCode = ref("");
        const ccvSearchType = ref("");
        const theFlightReservationList = ref({});

        let refBtnPrew = ref(null);
        let refBtnNext = ref(null);

        function setSearchResult(prmSearchCode, prmSearchType, prmFlightReservationList) {
            ccvSearchCode.value = prmSearchCode;
            ccvSearchType.value = prmSearchType;
            theFlightReservationList.value = prmFlightReservationList;


            if (ccvSearchType.value===libEnums.SearchType.Hotel.toUpperCase()) {
                processStep();
                //console.log("Stepper2.vue setSearchResult "+JSON.stringify((theFlightReservationList.value as any).HotelPrice));
                //setHotelPrice((theFlightReservationList.value as any).HotelPrice);
            }

        }

        function resetSearch() {
            arrHotelPrice.value = [];
        }

        function processStep() {
            console.log("Stepper2.vue processStep ccvSearchCode: "+AppCore.prsCore_Flight.ccvSearchCode);

            //AppCore.prsCore_Flight.ccvSearchProcessID = 0;

            store.dispatch(Actions.FLIGHT_RESERVATION_PACKAGE_RESULT, {
                "SearchCode": AppCore.prsCore_Flight.ccvSearchCode,
                "Type": "HOTEL",
            });

            /*
            store.dispatch(Actions.API_GET_PACKAGE_RESULT, {
                "SearchCode": AppCore.prsCore_Flight.ccvSearchCode,
                "Type": "HOTEL",
            });
             */

            (refBtnPrew.value as HTMLButtonElement).style.visibility = "";
            if (ccvSearchType.value===libEnums.SearchType.Hotel.toUpperCase()) {
                (refBtnPrew.value as HTMLButtonElement).style.visibility = "hidden";
            }
        }

        const dataFlightReservationPackageResult = computed(() => {
            const dataComputed = store.getters.getFlightReservationPackageResult;
            //console.log("dataFlightReservationPackageResult dataComputed "+JSON.stringify(dataComputed));
            if (dataComputed.Type!==undefined && dataComputed.data!==undefined && dataComputed.data.HotelPrice!==undefined) {
                if (dataComputed.Type==="HOTEL") {
                    setHotelPrice(dataComputed.data.HotelPrice);
                }
            }
            return dataComputed;
        });

        let arrHotelPrice = ref([]);

        function setHotelPrice(prmHotelPrice) {
            arrHotelPrice.value = prmHotelPrice;
        }

        function checkForNext() {
            if (AppCore.prsCore_Flight.theStepsBasket.Step2!==undefined && AppCore.prsCore_Flight.theStepsBasket.Step2.HotelPrice===undefined) {
                libTools.method.swalError("Tamam", "Hotel Odası Seçmelisiniz");
                return false;
            }
            return true;
        }

        function click_Prev() {
            console.log("click_Prev START")
            //store.dispatch(Actions.FLIGHT_RESERVATION_CUR_STEP, 1);
            emit("onGotoStepperStep", "prew", 1);
        }

        function click_Next() {
            console.log("click_Next START")
            if (checkForNext()) {

                //store.dispatch(Actions.FLIGHT_RESERVATION_CUR_STEP, 3);
                if (AppCore.prsCore_Flight.theSearchParams.TransferIncluded === 0) {
                    emit("onGotoStepperStep", "next", 4);
                } else {
                    emit("onGotoStepperStep", "next", 3);
                }

            }
        }

        return {
            setSearchResult, ccvSearchCode, ccvSearchType, theFlightReservationList,
            processStep, resetSearch,
            dataFlightReservationPackageResult,
            arrHotelPrice,
            refBtnPrew, refBtnNext,
            click_Prev, click_Next,
        };
    },
});

