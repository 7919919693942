

import {defineComponent, onMounted, ref} from "vue";

import Stepper1 from "@/components/new-reservation/fly-ticket/Stepper1.vue";
import Stepper2 from "@/components/new-reservation/hotel-list/Stepper2.vue";
import Stepper3 from "@/components/new-reservation/transfer/Stepper3.vue";
import Stepper4 from "@/components/new-reservation/guest-info/Stepper4.vue";
import Stepper5 from "@/components/new-reservation/payment/Stepper5.vue";
import Stepper6 from "@/components/new-reservation/completed/Stepper6.vue";
import {libEnums} from "@/core/appcore/libs/Lib_Enums";
import AppCore from "@/core/appcore/AppCore";

export default defineComponent({

    components: {
        Stepper1, Stepper2, Stepper3, Stepper4, Stepper5, Stepper6,
    },

    setup() {

        let refComp_Stepper1 = ref(null);
        let refComp_Stepper2 = ref(null);
        let refComp_Stepper3 = ref(null);
        let refComp_Stepper4 = ref(null);
        let refComp_Stepper5 = ref(null);
        let refComp_Stepper6 = ref(null);

        var curStepIndex = ref(0);

        let ccvLast_SearchProcessID = 0;

        onMounted(()=>{
            setSearchType(libEnums.SearchType.Package);
        });

        function setSearchType(prmSearchType) {
            resetSearch(prmSearchType);
        }

        function resetSearch(prmSearchType) {
            console.log("SearchResultCard resetSearch START "+prmSearchType);
            setSearchResult("", prmSearchType, []);

            (refComp_Stepper1.value as any).resetSearch();
            (refComp_Stepper2.value as any).resetSearch();
        }

        function searchStarted(prmSearchType) {
            console.log("SearchResultCard searchStarted START "+prmSearchType);
            resetSearch(prmSearchType);
        }

        function setSearchResult(prmSearchCode, prmSearchType, prmFlightReservationList) {
            (refComp_Stepper1.value as any).setSearchResult(prmSearchCode, prmSearchType, prmFlightReservationList);
            (refComp_Stepper2.value as any).setSearchResult(prmSearchCode, prmSearchType, prmFlightReservationList);
            (refComp_Stepper3.value as any).setSearchResult(prmSearchCode, prmSearchType, prmFlightReservationList);
            (refComp_Stepper4.value as any).setSearchResult(prmSearchCode, prmFlightReservationList);

            console.log("SearchResultCard setSearchResult prmSearchCode: "+prmSearchCode+" prmSearchType: "+prmSearchType+ " ccvLast_SearchProcessID: "+ccvLast_SearchProcessID+" "+AppCore.prsCore_Flight.ccvSearchProcessID);



            if (prmSearchCode==="" && ccvLast_SearchProcessID!==AppCore.prsCore_Flight.ccvSearchProcessID){
                resetSteps(prmSearchType);
            }
            ccvLast_SearchProcessID = AppCore.prsCore_Flight.ccvSearchProcessID;
        }

        function resetSteps(prmSearchType) {
            console.log("resetSteps START prmSearchType: "+prmSearchType);

            (document.getElementById("divNavStepper_MakeReservation_1") as HTMLElement).style.display= "";
            (document.getElementById("divNavStepper_MakeReservation_2") as HTMLElement).style.display= "";
            (document.getElementById("divNavStepper_MakeReservation_3") as HTMLElement).style.display= "";

            if (prmSearchType.toUpperCase()===libEnums.SearchType.Package.toUpperCase()) {
                setCurStep(1);
                console.log("resetSteps To Step_1");
            }
            if (prmSearchType.toUpperCase()===libEnums.SearchType.Hotel.toUpperCase()) {
                setCurStep(2);
                console.log("resetSteps To Step_2");
                (document.getElementById("divNavStepper_MakeReservation_1") as HTMLElement).style.display= "none";
                if (AppCore.prsCore_Flight.theSearchParams.TransferIncluded===0) {
                    (document.getElementById("divNavStepper_MakeReservation_3") as HTMLElement).style.display= "none";
                }
            }
            if (prmSearchType.toUpperCase()===libEnums.SearchType.Transfer.toUpperCase()) {
                setCurStep(3);
                (document.getElementById("divNavStepper_MakeReservation_1") as HTMLElement).style.display= "none";
                (document.getElementById("divNavStepper_MakeReservation_2") as HTMLElement).style.display= "none";
                console.log("resetSteps To Step_3");
            }
            console.log("resetSteps END");
        }

        function setCurStep(prmStepIndex) {
            curStepIndex.value= prmStepIndex;
            addOrRemoveClassFromStep("remove", 1);
            addOrRemoveClassFromStep("remove", 2);
            addOrRemoveClassFromStep("remove", 3);
            addOrRemoveClassFromStep("remove", 4);
            addOrRemoveClassFromStep("remove", 5);
            addOrRemoveClassFromStep("remove", 6);
            addOrRemoveClassFromStep("add", curStepIndex.value);
        }

        function addOrRemoveClassFromStep(prmActionType, prmStepIndex){
            const elNav = document.getElementById("divNavStepper_MakeReservation_"+prmStepIndex);
            if (elNav!==null) {
                if (prmActionType==="remove") {
                    elNav.classList.remove("current");
                } else {
                    elNav.classList.add("current");
                }
            }
            const elStep = document.getElementById("divStepperCont_MakeReservation_Step_"+prmStepIndex);
            if (elStep!==null) {
                if (prmActionType==="remove") {
                    elStep.classList.remove("current");
                } else {
                    elStep.classList.add("current");
                }
            }
        }

        function gotoSteperStep(prmStepType, prmStepIndex){
            setCurStep(prmStepIndex);

            if (prmStepIndex===2) {
                (refComp_Stepper2.value as any).processStep();
            }
            if (prmStepIndex===3) {
                (refComp_Stepper3.value as any).processStep();
            }
            if (prmStepIndex===4) {
                (refComp_Stepper4.value as any).processStep();
            }
            if (prmStepIndex===5) {
                (refComp_Stepper5.value as any).processStep();
            }
        }

        return {
            refComp_Stepper1, refComp_Stepper2, refComp_Stepper3, refComp_Stepper4, refComp_Stepper5, refComp_Stepper6,
            setSearchResult,
            curStepIndex,
            setSearchType,
            searchStarted,
            resetSearch,
            gotoSteperStep,
            resetSteps,
        };
    }

});

