<template>
    <!--begin::Wrapper-->
    <div class="w-100 p-4">
        <div class="card p-10 shadow-sm text-gray-600 fs-3" style="display: none;" id="divCard_ReservationCompleted">
            <span class=" fw-bold">Rezervasyon işlemleriniz tamamlanmıştır.</span>
            <span class=" fw-bold fs-5">Rezervasyon numaranız: <b><a :href="`#/apps/sys/reservations/resDetail/${ccvRezID}`">{{ccvRezNo}}</a></b> </span>


            <button type="button" class="btn btn-lg btn-primary" @click="click_Test_MakeReservation" style="display: none;">
                Make Res<span class="svg-icon svg-icon-4 ms-1 me-0"><inline-svg src="media/icons/duotune/arrows/arr064.svg" /></span>
            </button>


        </div>
    </div>
    <!--end::Wrapper-->

    <p style="display: none;">dataMakeReservationResult: {{dataMakeReservationResult}}</p>

</template>

<script>

import {computed, defineComponent, ref} from "vue";
import store from "@/store";
import AppCore from "@/core/appcore/AppCore";
import {Actions} from "@/store/enums/StoreEnums";

export default defineComponent({

    setup() {

        var ccvRezNo = ref("");
        var ccvRezID = ref(0);

        const dataMakeReservationResult = computed(() => {
            const dataComputed = store.getters.getMakeReservationResult;
            console.log("getMakeReservationResult "+dataComputed);
            if (dataComputed!==undefined && dataComputed.data!==undefined) {
                setRezNo(dataComputed.data.VoucherNo, dataComputed.data.ID);
            }
            return dataComputed;
        });

        function setRezNo (prmRezNo, prmRezID) {
            ccvRezNo.value = prmRezNo;
            ccvRezID.value = prmRezID;

            document.getElementById("divCard_ReservationCompleted").style.display="";
        }

        function click_Test_MakeReservation() {
            makeReservation();
        }

        function makeReservation() {
            let vvPayload = {"SearchCode" : AppCore.prsCore_Flight.ccvSearchCode,};
            store.dispatch(Actions.FLIGHT_RESERVATION_MAKE_RESERVATION, vvPayload);
        }


        return {
            ccvRezNo, ccvRezID, dataMakeReservationResult,
            click_Test_MakeReservation,
        };
    },

});

</script>