
import {defineComponent, ref, computed, onMounted} from "vue";
import Search from "@/components/reservations/makereservation/search/Search.vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import AppCore from "@/core/appcore/AppCore";

export default defineComponent({
    name: "Make-Reservation-Search-Card",
    components: {
        Search,
    },
    setup(props, {emit}) {

        let refComp_Search_Package = ref(null);
        let refComp_Search_Hotel = ref(null);
        let refComp_Search_Transfer = ref(null);

        onMounted(()=>{
            store.dispatch(Actions.MARKETS_LIST_CACHE, {});
        });

        function searchStarted(prmSearchType) {
            //console.log("searchCard searchStarted START "+prmSearchType);
            emit("on-search-started", prmSearchType);
        }

        function searchFinished() {
            (refComp_Search_Package.value as any).searchFinished();
            (refComp_Search_Hotel.value as any).searchFinished();
            (refComp_Search_Transfer.value as any).searchFinished();
        }

        function click_SearchType(prmSearchType) {
            AppCore.prsCore_Flight.setSearchType(prmSearchType);
            emit("on-search-type-changed", prmSearchType);
        }

        return {
            refComp_Search_Package, refComp_Search_Hotel, refComp_Search_Transfer,
            searchStarted, searchFinished,
            click_SearchType,
        };
    }
});
