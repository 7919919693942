
import {defineComponent, onMounted, ref} from "vue";
import FlyTicketContainer from "@/components/new-reservation/fly-ticket/FlyTicketContainer.vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import AppCore from "@/core/appcore/AppCore";
import {libTools} from "@/core/appcore/libs/Lib_Tools";

export default defineComponent({
    components: {
        FlyTicketContainer,
    },
    setup(props, {emit}) {

        const ccvSearchCode = ref("");
        const ccvSearchType = ref("");
        const theFlightReservationList = ref({});

        let ccvFlightKind = ref(1);

        function setSearchResult(prmSearchCode, prmSearchType,  prmFlightReservationList) {
            ccvSearchCode.value = prmSearchCode;
            ccvSearchType.value = prmSearchType;
            theFlightReservationList.value = prmFlightReservationList;
        }

        function resetSearch() {
            theFlightReservationList.value = [];
            clearRadioGroupValue("FlightSel_Dep");
            clearRadioGroupValue("FlightSel_Ret");
        }


        function click_FlightKind(prmFlightKind) {
            ccvFlightKind.value = prmFlightKind;
        }

        function checkForNext() {

            if (AppCore.prsCore_Flight.theStepsBasket.Step1===undefined) {AppCore.prsCore_Flight.theStepsBasket.Step1 = {};}

            if (AppCore.prsCore_Flight.theStepsBasket.Step1.FlightDep===undefined) {
                libTools.method.swalError("Tamam", "Gidiş Uçuşu Seçiniz");
                return false;
            }

            if (AppCore.prsCore_Flight.theStepsBasket.Step1.FlightRet===undefined) {
                libTools.method.swalError("Tamam", "Dönüş Uçuşu Seçiniz");
                return false;
            }

            return true;
        }

        function click_Next() {
            if (checkForNext()) {
                if (AppCore.prsCore_Flight.theSearchParams.Type2==="Flight") {
                    emit("onGotoStepperStep", "next", 4);
                } else {
                    emit("onGotoStepperStep", "next", 2);
                }
            }
        }

        function clearRadioGroupValue(prmName){
            console.log("clearRadioGroupValue START "+prmName);
            let arrEls = document.getElementsByName(prmName);
            for (let i=0; i<arrEls.length; i++) {
                let el = arrEls[i] as HTMLInputElement;
                el.checked = false;
                console.log("clearRadioGroupValue ["+i+"] name: "+el.getAttribute("name"));
            }
        }

        return {
            AppCore, libTools,
            setSearchResult, ccvSearchCode, ccvSearchType, theFlightReservationList,
            ccvFlightKind,
            click_FlightKind,
            click_Next,
            resetSearch,
        };
    },
});
