

import {computed, defineComponent, ref} from "vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import AppCore from "@/core/appcore/AppCore";
import AutoComplete from "@/components/appcomps/forms/AutoComplete.vue"
import TransferSection from "@/components/new-reservation/transfer/TransferSection.vue";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import {libEnums} from "@/core/appcore/libs/Lib_Enums";

interface TransferInfo {
    FlightNo: string,
    TransferTime: string,
    Airport: string,
}

export default defineComponent({
    components: {
        AutoComplete,
        TransferSection,
    },
    setup(props, {emit}) {

        const ccvSearchCode = ref("");
        const ccvSearchType = ref("");
        const theFlightReservationList = ref({});

        let refBtnPrew = ref(null);
        let refBtnNext = ref(null);

        const theTransferInfoArr = ref<TransferInfo>({
            FlightNo: "",
            TransferTime: "",
            Airport: "",
        });

        const theTransferInfoRet = ref<TransferInfo>({
            FlightNo: "",
            TransferTime: "",
            Airport: "",
        });

        function setSearchResult(prmSearchCode, prmSearchType, prmFlightReservationList) {
            ccvSearchCode.value = prmSearchCode;
            ccvSearchType.value = prmSearchType;
            theFlightReservationList.value = prmFlightReservationList;

            console.log("Stepper3.vue setSearchResult "+prmSearchCode+" "+prmSearchType);
            console.log(prmFlightReservationList);
            setPackageResult(prmFlightReservationList);
        }

        function processStep() {
            console.log("Stepper3.vue processStep "+ccvSearchType.value);
            store.dispatch(Actions.FLIGHT_RESERVATION_TRANSFERS, {
                "SearchCode": AppCore.prsCore_Flight.ccvSearchCode,
            });

            (document.getElementById("divStepper_Transfer_Airport_Arrival") as HTMLElement).style.setProperty("display", "none", "important");
            (document.getElementById("divStepper_Transfer_Airport_Departure") as HTMLElement).style.setProperty("display", "none", "important");
            if (ccvSearchType.value===libEnums.SearchType.Hotel.toUpperCase()) {
                (document.getElementById("divStepper_Transfer_Airport_Arrival") as HTMLElement).style.setProperty("display", "block", "important");
                (document.getElementById("divStepper_Transfer_Airport_Departure") as HTMLElement).style.setProperty("display", "block", "important");
            }

            (refBtnPrew.value as HTMLButtonElement).style.visibility = "";
            if (ccvSearchType.value===libEnums.SearchType.Transfer.toUpperCase()) {
                (refBtnPrew.value as HTMLButtonElement).style.visibility = "hidden";
            }
        }

        const dataFlightReservationTransfers = computed(() => {
            const dataComputed = store.getters.getFlightReservationTransfers;
            //console.log("dataFlightReservationTransfers dataComputed "+JSON.stringify(dataComputed));
            if (dataComputed.data!==undefined) {
                setPackageResult(dataComputed.data);
            }
            return dataComputed;
        });

        let valPackageResult = ref([]);

        function setPackageResult(prmPackageResult) {
            valPackageResult.value = prmPackageResult;
        }

        //## Airports (Arrival)
        function keyupAutoCompAirportsArrival(prmInputValue) {
            //console.log("keyupAutoCompAirportsFrom prmInputValue: "+prmInputValue);
            let myPayload = {};
            if (prmInputValue.length === 3) {
                myPayload = {'CallFrom': "FROM", 'IATACode': prmInputValue.toUpperCase()};
                //console.log("keyupAutoCompAirportsFrom A SENDING myPayload: "+JSON.stringify(myPayload));
                store.dispatch(Actions.AIRPORTS_LIST_ALL, myPayload);
            } else if (prmInputValue.length === 4) {
                myPayload = {'CallFrom': "FROM", 'Title': prmInputValue};
                //console.log("keyupAutoCompAirportsFrom SENDING B myPayload: "+JSON.stringify(myPayload));
                store.dispatch(Actions.AIRPORTS_LIST_ALL, myPayload);
            }
        };

        function selectItemAutoCompAirportsArrival(prmItem) {
            console.log("selectItemAutoCompAirportsArrival "+JSON.stringify(prmItem))
            theTransferInfoArr.value.Airport = prmItem.id;
        };

        function itemProjectionAirportsArrival (prmItem) {
            return prmItem.name;
        };

        const dataAirportsListArrival = computed(() => {
            let dataComputed = store.getters.airportsList;
            if (dataComputed!==undefined) {
                return dataComputed;
            }
            return {data:[]};
        });

        const lstAutoCompleteItemsAirportsArrival = computed(() => {
            let arrTmp: unknown[] = [];
            if (dataAirportsListArrival.value!==undefined && dataAirportsListArrival.value.data !== undefined) {
                for (var each of dataAirportsListArrival.value.data) {
                    arrTmp.push({"id": each.IATACode, "name": "("+each.IATACode+") "+ each.Title});
                }
            }
            return arrTmp;
        });

        //## Airports (Departure)
        function keyupAutoCompAirportsDeparture(prmInputValue) {
            //console.log("keyupAutoCompAirportsFrom prmInputValue: "+prmInputValue);
            let myPayload = {};
            if (prmInputValue.length === 3) {
                myPayload = {'CallFrom': "FROM", 'IATACode': prmInputValue.toUpperCase()};
                //console.log("keyupAutoCompAirportsFrom A SENDING myPayload: "+JSON.stringify(myPayload));
                store.dispatch(Actions.AIRPORTS_LIST_ALL, myPayload);
            } else if (prmInputValue.length === 4) {
                myPayload = {'CallFrom': "FROM", 'Title': prmInputValue};
                //console.log("keyupAutoCompAirportsFrom SENDING B myPayload: "+JSON.stringify(myPayload));
                store.dispatch(Actions.AIRPORTS_LIST_ALL, myPayload);
            }
        };

        function selectItemAutoCompAirportsDeparture(prmItem) {
            console.log("selectItemAutoCompAirportsDeparture "+JSON.stringify(prmItem))
            theTransferInfoRet.value.Airport = prmItem.id;
        };

        function itemProjectionAirportsDeparture (prmItem) {
            return prmItem.name;
        };

        const dataAirportsListDeparture = computed(() => {
            let dataComputed = store.getters.airportsList;
            if (dataComputed!==undefined) {
                return dataComputed;
            }
            return {data:[]};
        });

        const lstAutoCompleteItemsAirportsDeparture = computed(() => {
            let arrTmp: unknown[] = [];
            if (dataAirportsListDeparture.value!==undefined && dataAirportsListDeparture.value.data !== undefined) {
                for (var each of dataAirportsListDeparture.value.data) {
                    arrTmp.push({"id": each.IATACode, "name": "("+each.IATACode+") "+ each.Title});
                }
            }
            return arrTmp;
        });

        function transferSelected(prmDirection, prmTransferPrice) {
            let vvIsInfoOk = true;
            if (ccvSearchType.value===libEnums.SearchType.Hotel.toUpperCase() || ccvSearchType.value===libEnums.SearchType.Transfer.toUpperCase()) {

                if (prmDirection==="Arrival") {
                    if (vvIsInfoOk && theTransferInfoArr.value.FlightNo == "") {
                        clearRadioGroupValue("radio_Transfer_Price_Arrival");
                        libTools.method.swalError("Tamam", 'Havaalanı - Otel "Uçuş No" girmelisiniz');
                        vvIsInfoOk = false;
                    }
                    if (vvIsInfoOk && theTransferInfoArr.value.TransferTime == "") {
                        clearRadioGroupValue("radio_Transfer_Price_Arrival");
                        libTools.method.swalError("Tamam", 'Havaalanı - Otel "Transfer Saati" girmelisiniz');
                        vvIsInfoOk = false;
                    }
                    if (vvIsInfoOk && theTransferInfoArr.value.Airport == "") {
                        clearRadioGroupValue("radio_Transfer_Price_Arrival");
                        libTools.method.swalError("Tamam", 'Havaalanı - Otel "Havalanı" seçmelisiniz');
                        vvIsInfoOk = false;
                    }
                }

                if (prmDirection==="Return") {
                    if (vvIsInfoOk && theTransferInfoRet.value.FlightNo == "") {
                        clearRadioGroupValue("radio_Transfer_Price_Return");
                        libTools.method.swalError("Tamam", 'Otel - Havaalanı "Uçuş No" girmelisiniz');
                        vvIsInfoOk = false;
                    }
                    if (vvIsInfoOk && theTransferInfoRet.value.TransferTime == "") {
                        clearRadioGroupValue("radio_Transfer_Price_Return");
                        libTools.method.swalError("Tamam", 'Otel - Havaalanı "Transfer Saati" girmelisiniz');
                        vvIsInfoOk = false;
                    }
                    if (vvIsInfoOk && theTransferInfoRet.value.Airport == "") {
                        clearRadioGroupValue("radio_Transfer_Price_Return");
                        libTools.method.swalError("Tamam", 'Otel - Havaalanı "Havalanı" seçmelisiniz');
                        vvIsInfoOk = false;
                    }
                }

            }

            if (vvIsInfoOk) {
                let vvType = "";
                let vvFlightInfo;
                if (prmDirection==="Arrival") {
                    AppCore.prsCore_Flight.theStepsBasket.Step3.TransferArrival = prmTransferPrice;
                    vvType = "TRANSFERARRIVAL";
                    vvFlightInfo = theTransferInfoArr.value;
                }
                if (prmDirection==="Return") {
                    AppCore.prsCore_Flight.theStepsBasket.Step3.TransferReturn = prmTransferPrice;
                    vvType = "TRANSFERRETURN";
                    vvFlightInfo = theTransferInfoRet.value;
                }

                let vvPayload = {
                    "SearchCode" : AppCore.prsCore_Flight.ccvSearchCode,
                    "PriceCode": prmTransferPrice.PriceCode,
                    "Type": vvType,
                    "FlightNo": vvFlightInfo.FlightNo,
                    "TransferTime": vvFlightInfo.TransferTime,
                    "Airport": vvFlightInfo.Airport,
                };

                console.log("transferSelected vvPayload: "+JSON.stringify(vvPayload));

                store.dispatch(Actions.FLIGHT_RESERVATION_ADD, vvPayload);

                AppCore.prsCore_Flight.funcSummary_PrsStepsBasket();
            }

        }

        function checkForNext() {

            if (AppCore.prsCore_Flight.theStepsBasket.Step3.TransferArrival===undefined) {
                libTools.method.swalError("Tamam", "Havaalanı - Otel Transferi Seçmelisiniz");
                return false;
            }

            if (AppCore.prsCore_Flight.theStepsBasket.Step3.TransferReturn===undefined) {
                libTools.method.swalError("Tamam", "Otel - Havaalanı Transferi Seçmelisiniz");
                return false;
            }

            return true;
        }

        function click_Prev() {
            //store.dispatch(Actions.FLIGHT_RESERVATION_CUR_STEP, 2);
            emit("onGotoStepperStep", "prew", 2);
        }

        function click_Next() {
            if (checkForNext()) {
                //store.dispatch(Actions.FLIGHT_RESERVATION_CUR_STEP, 4);
                emit("onGotoStepperStep", "next", 4);
            }
        }

        function clearRadioGroupValue(prmName){
            console.log("clearRadioGroupValue START "+prmName);
            let arrEls = document.getElementsByName(prmName);
            for (let i=0; i<arrEls.length; i++) {
                let el = arrEls[i] as HTMLInputElement;
                el.checked = false;
                console.log("clearRadioGroupValue ["+i+"] name: "+el.getAttribute("name"));
            }
        }

        return {
            setSearchResult,
            ccvSearchType,
            processStep,
            dataFlightReservationTransfers,
            valPackageResult,
            transferSelected,
            clearRadioGroupValue,
            theTransferInfoArr, theTransferInfoRet,
            keyupAutoCompAirportsArrival, selectItemAutoCompAirportsArrival, itemProjectionAirportsArrival, lstAutoCompleteItemsAirportsArrival,
            keyupAutoCompAirportsDeparture, selectItemAutoCompAirportsDeparture, itemProjectionAirportsDeparture, lstAutoCompleteItemsAirportsDeparture,
            refBtnPrew, refBtnNext,
            click_Prev, click_Next,
        };
    },
});

